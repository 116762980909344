import { Router } from 'vue-router'
import Velocity from 'velocity-animate'
import { ISitemapNode } from '@cloudfun/core'

const linkTo = (menu: ISitemapNode, router: Router): void => {
  if (menu.subNodes) {
    menu.activeDropdown = !menu.activeDropdown
  } else if (menu.to) {
    router.push(menu.to)
  }
}

const enter = (el: HTMLElement, done: () => void): void => {
  Velocity(el, 'slideDown', { duration: 300 }, { complete: done })
}

const leave = (el: HTMLElement, done: () => void): void => {
  Velocity(el, 'slideUp', { duration: 300 }, { complete: done })
}

export { linkTo, enter, leave }
