<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <div v-for="(node, index) in $breadcrumb" :key="`breadcrumb-${index}`">
        <div class="breadcrumb sm:flex" v-if="$breadcrumb.length === index + 1 || $breadcrumb[index+1].title !== node.title">
          <ChevronRightIcon v-if="index !== 0" class="breadcrumb__icon" />
          <router-link v-if="node.to && $breadcrumb.length !== index + 1" :to="node.to">{{ node.title }}</router-link>
          <span v-else class="breadcrumb--active">{{ node.title }}</span>
        </div>
      </div>
    </div>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <div class="intro-x relative mr-3 sm:mr-6">
      <!-- <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input input placeholder-theme-13"
          placeholder="Search..."
          @focus="showSearchDropdown"
          @blur="hideSearchDropdown"
        />
        <SearchIcon class="search__icon dark:text-gray-300" />
      </div>
      <a class="notification sm:hidden" href>
        <SearchIcon class="notification__icon dark:text-gray-300" />
      </a>
      <div class="search-result" :class="{ show: searchDropdown }">
        <div class="search-result__content">
          <div class="search-result__content__title">Pages</div>
          <div class="mb-5">
            <a href class="flex items-center">
              <div
                class="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"
              >
                <InboxIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Mail Settings</div>
            </a>
            <a href class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"
              >
                <UsersIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Users & Permissions</div>
            </a>
            <a href class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"
              >
                <CreditCardIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Transactions Report</div>
            </a>
          </div>
          <div class="search-result__content__title">Users</div>
          <div class="mb-5">
            <a
              href
              class="flex items-center mt-2"
            >
              <div class="w-8 h-8 image-fit">
                <img
                  alt="Midone Tailwind HTML Admin Template"
                  class="rounded-full"
                  src="/images/no_photo.png"
                />
              </div>
              <div class="ml-3">Tester</div>
              <div
                class="ml-auto w-48 truncate text-gray-600 text-xs text-right"
              >
                test@cloudfun.com.tw
              </div>
            </a>
          </div>
          <div class="search-result__content__title">Products</div>
          <a
            href
            class="flex items-center mt-2"
          >
            <div class="w-8 h-8 image-fit">
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full"
                src="/images/no_photo.png"
              />
            </div>
            <div class="ml-3">名稱</div>
            <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">
              分類
            </div>
          </a>
        </div>
      </div> -->
    </div>
    <!-- END: Search -->
    <!-- BEGIN: Notifications -->
    <div class="intro-x dropdown relative mr-auto sm:mr-6">
      <!-- <div
        class="dropdown-toggle notification notification--bullet cursor-pointer"
      >
        <BellIcon class="notification__icon dark:text-gray-300" />
      </div>
      <div class="notification-content pt-2 dropdown-box">
        <div
          class="notification-content__box dropdown-box__content box dark:bg-dark-6"
        >
          <div class="notification-content__title">Notifications</div>
          <div class="cursor-pointer relative flex items-center mb-5">
            <div class="w-12 h-12 flex-none image-fit mr-1">
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full"
                src="/images/no_photo.png"
              />
              <div
                class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
              ></div>
            </div>
            <div class="w-full ml-2 overflow-hidden">
              <div class="flex items-center">
                <a href="javascript:;" class="font-medium truncate mr-5">
                  Tester
                </a>
                <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">
                  05:09 AM
                </div>
              </div>
              <div class="w-full truncate text-gray-600 mt-0.5">
                Notifincation Test
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
      >
        <img
          v-if="$user.PhotoUri"
          alt="Midone Tailwind HTML Admin Template"
          :src="$user.PhotoUri"
          onerror="this.onerror=null;this.src='/images/no_photo.png'"
        />
        <img
          v-else
          alt="Midone Tailwind HTML Admin Template"
          src="/images/no_photo.png"
        />
      </div>
      <div class="dropdown-box w-56">
        <div
          class="dropdown-box__content box bg-theme-38 dark:bg-dark-6 text-white"
        >
          <div class="p-4 border-b border-theme-40 dark:border-dark-3">
            <div class="text-xs text-theme-41 mt-0.5 dark:text-gray-600">
              Signed in as:
            </div>
            <div class="font-medium">{{ $user.Name }}<span v-if="!isGuest">({{$user.Account}})</span></div>
          </div>
          <div class="p-2" v-show="!isGuest">
            <a class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              href="javascript: void(0)"
              @click="openChangePasswordModal"
            >
              <LockIcon class="w-4 h-4 mr-2" /> 變更密碼
            </a>
            <a class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md" @click="logout()">
              <ToggleLeftIcon class="w-4 h-4 mr-2" /> 登出
            </a>
          </div>
          <div class="p-2 border-t border-theme-40 dark:border-dark-3">
            <a v-if="isGuest" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md" @click="logout()">
              <ToggleRightIcon class="w-4 h-4 mr-2" /> 登入
            </a>
            <!-- <a class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
              <HelpCircleIcon class="w-4 h-4 mr-2" /> 支援服務
            </a> -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
  <vxe-modal show-zoom="true" lock-scroll="false" v-model="form.showEdit" :title="'更換密碼'" width="1000" min-width="600" min-height="300" resize destroy-on-close>
    <template #default>
      <vxe-form :data="form.data" :items="form.formItems" :rules="form.formRules" title-align="right" title-width="100" @submit="submitModal"></vxe-form>
    </template>
  </vxe-modal>
</template>

<script lang="ts">
/* eslint-disable */
import context from '@cloudfun/core'
import { defineComponent, ref, reactive } from 'vue'
import { mapGetters } from 'vuex'
import { VxeFormPropTypes } from "vxe-table";

export default defineComponent({
  setup () {
    const searchDropdown = ref(false)
    const model = context.current?.model;
    
    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    const form = reactive({
      showEdit: false,
      data:{
        password: '',
        password2: '',
      },
      formRules: {              
        password: [{ 
          required: true, 
          message: context.current?.user?.Type == 6 ? '請輸入密碼(須12碼以上含大小寫英文、數字)' : '請輸入密碼(須8碼以上含大小寫英文、數字)', 
          pattern: 
            context.current?.user?.Type == 6 ? 
              new RegExp("^((?=.{12,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{12,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)")
            : new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)")          
          }],
        password2: [{
          required: true,
          message: context.current?.user?.Type == 6 ? '請輸入確認密碼(須12碼以上含大小寫英文、數字)' : '請輸入確認密碼(須8碼以上含大小寫英文、數字)', 
          pattern: 
            context.current?.user?.Type == 6 ? 
              new RegExp("^((?=.{12,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{12,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)")
            : new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)")          
          }],
      } as VxeFormPropTypes.Rules,
      formItems: [
        { field: 'password', title: '密碼', span: 24, itemRender: { name: '$input', props: { type: 'password', placeholder: '密碼' } } },
        { field: 'password2', title: '確認密碼', span: 24, itemRender: { name: '$input', props: { type: 'password', placeholder: '確認密碼' } } },       
        { align: 'right', span: 24, titleAlign: 'left', itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確認', status: 'primary' } } , { props: { type: 'reset', content: '重置' } }] } } //, events: { click: save }
      ] as VxeFormPropTypes.Items
    })

    const openChangePasswordModal = () => {
      form.showEdit = true    
      form.data.password = ''
      form.data.password2 = ''
    }

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      isGuest: !context.current?.user.Id,
      openChangePasswordModal,
      form,
    }
  },
  computed: {
    ...mapGetters(['user']),
    homePath () {
      const path = this.$route.path
      if (path.startsWith('/simple-menu')) return '/simple-menu'
      if (path.startsWith('/top-menu')) return '/top-menu'
      return '/'
    }
  },
  methods: {
    logout () {
      if (!this.$user.Id) {
        this.$router.push('/login')
        return
      }
      this.$model.dispatch('logout').finally(() => {
        this.$model.user = this.$model.guest
        this.$router.push('/login')
      })
    },
    submitModal() {
      if(this.form.data.password != this.form.data.password2) {
        context.send('error', { subject: '操作失敗', content: '密碼不一致，請確認輸入！' })
        return;
      }      
      this.$model.dispatch(`user/changePassword`, { password: this.form.data.password, key: this.$user.Id  }).then(
        paylod => {
          context.send('info', { subject: '操作成功', content: '密碼更換成功，請重新登入' })
          this.form.showEdit = false;
          setTimeout(() => {
            this.logout();
          }, 3000);
        },
        failure => context.send('error', { subject: '操作失敗', content: failure})
      ).finally(() => {
      });      
    },
  },
  watch: {
    user (current) {
      this.isGuest = !current.Id
    }
  }
})
</script>
